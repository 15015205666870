@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.applyFadeIn {
  animation: fadeIn 500ms forwards 1;
}

.allCompMainBody {
  display: flex;
  justify-content: center;
  max-height: 94.5vh;
  overflow-y: scroll;
  padding-left: 3rem;
}

.allCompParent {
  opacity: 0;
}

.allCompTitle {
  margin-top: 3rem;
  font-size: 2.5rem;
}

.query {
  color: brown;
}

.allCompBody {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 2rem;
  padding-bottom: 3rem;
}

.allComposersHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

.sectionHeader {
  width: 100%;
}

.letterHeader {
  position: relative;
  width: inherit;
  margin-top: 1rem;
  font-size: 2rem;
  display: block;
  width: fit-content;
  font-weight: bold;
  padding-left: 0.1rem;
}

.allCompGrid {
  display: grid;
  justify-content: space-between;
  gap: 0rem 4rem;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 0.25rem;
}

.noResults {
  color: brown;
  grid-column: 1 / -1;
  /*Span all cols*/
  justify-self: center;
  font-size: 1.25rem;
  font-weight: bold;
}

.allCompItem {
  display: flex;
  align-items: center;
  border: 1px solid rgba(128, 128, 128, 0.705);
  border-radius: 10px;
  width: 22rem;
  margin-bottom: 1rem;
  transition: all ease-in-out 0.1s;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.allCompItem:hover {
  background-color: brown;
  cursor: pointer;
}

.allCompItem:hover>.allCompItemName {
  color: white;
}

.allCompItemImage {
  border-radius: 10%;
}

.allCompItemName {
  margin-left: 0.75rem;
  font-size: 1.5rem;
}

.allCompItemLink {
  text-decoration: none;
  color: black;
}

.button {
  border: 1px solid black;
  background-color: white;
  color: black;
  border-radius: 5px;
  padding: 0.3rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.button:hover {
  background-color: brown;
  color: white;
}

.buttonImage:hover {
  color: white;
}

@media (max-width: 1750px) {
  .allCompItem {
    width: 20rem;
  }
}

@media (max-width: 1625px) {

  .allCompGrid {
    grid-template-columns: repeat(3, 1fr);
    gap: 0rem 6rem;
  }

  .allCompItem {
    width: 22rem;
  }

}

@media (max-width: 1350px) {

  .allCompGrid {
    grid-template-columns: repeat(3, 1fr);
    gap: 0rem 2rem;
  }

  .allCompItem {
    width: 20rem;
  }
}

@media (max-width: 1150px) {

  .allCompGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: 0rem 4rem;
  }

  .allCompItem {
    width: 23rem;
  }
}

@media (max-width: 875px) {

  .allCompGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: 0rem 2rem;
  }

  .allCompItem {
    width: 20rem;
  }
}

@media (max-width: 765px) {

  .allComposersHeader {
    flex-direction: column;
    gap: 0.5rem 0rem;
    align-items: flex-start;
  }

  .allCompGrid {
    grid-template-columns: repeat(1, 1fr);
  }

  .allCompItem {
    width: 24rem;
  }
}