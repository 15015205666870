@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.applyFadeIn {
    animation: fadeIn 500ms forwards 1;
}

.allWorksMainBody {
    display: flex;
    justify-content: center;
    max-height: 94.5vh;
    overflow-y: auto;
    padding-left: 3rem;
}

.allWorksParent {
    opacity: 0;
}

.allWorksTitle {
    margin-top: 3rem;
    font-size: 2.5rem;
}

.query {
    color: brown;
}

.allWorksBody {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    padding-bottom: 3rem;
    min-width: 82.5vw;
}

.searchParent {
    position: relative;
    width: 100%;
    margin-top: 2rem;
}

.searchForWork {
    min-width: 85vw;
    max-width: 85vw;
    border: none;
    border-bottom: 1px solid black;
    font-size: 1.3rem;
    padding-bottom: 0.1rem;
}

.searchForWork:focus {
    outline: none;
    border-bottom: 2px solid brown;
}

.searchIcon {
    position: absolute;
    right: 0.1rem;
}

.searchResult {
    display: flex;
    flex-direction: column;
    gap: 1rem 0rem;
    max-width: 85vw;
    min-width: 85vw;
    align-items: center;
}

.workCard {
    display: grid;
    grid-template-columns: 3fr 2fr 0fr;
    column-gap: 5rem;
    max-width: 85vw;
    min-width: 85vw;
    align-items: center;
    font-size: 1.25rem;
    border-bottom: 1px solid black;
    padding-bottom: 0.5rem;
    white-space: pre-wrap;
    word-break: break-word;
}

.noResults {
    font-size: 1.25rem;
}

@media (max-width: 1280px) {
    .allWorksMainBody {
        max-height: 92vh;
    }
}

@media (max-width: 1000px) {
    .searchResult {
        gap: 1rem 0rem;
    }

    .workCard {
        grid-template-columns: 4fr 2fr 0fr;
        font-size: 1.1rem;
        padding-bottom: 0.5rem;
    }
}