.mainBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4rem;
    gap: 4rem 0rem;
    max-height: 94vh;
    min-height: 94vh;
    overflow-y: scroll;
}

.sectionGroup {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 70vw;
}

.sectionTitle {
    font-size: 2rem;
    font-weight: bold;
    border-bottom: 2px solid brown;
    display: flex;
    cursor: pointer;
    padding-bottom: 0.1rem;
}

.sectionDesc {
    font-size: 1.4rem;
    margin-top: 0.5rem;
}

ul {
    margin: 0;
    padding: 0rem 1.5rem;
}

.dataRelDescription {
    display: flex;
    flex-direction: column;
    gap: 0.5rem 0rem;
    padding-bottom: 5rem;
}

ol {
    margin: 0;
    padding: 0rem 2rem;
}

ol>li::marker {
    font-weight: bold;
}

@media (max-width: 1280px) {
    .mainBody {
        gap: 3rem 0rem;
    }

    .sectionTitle {
        font-size: 1.75rem;
    }

    .sectionDesc {
        font-size: 1.25rem;
    }
}

@media (max-width: 1015px) {
    .mainBody {
        gap: 3rem 0rem;
        padding-top: 2rem;
    }

    .sectionTitle {
        font-size: 1.5rem;
    }

    .sectionDesc {
        font-size: 1rem;
    }
}