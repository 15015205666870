.homeBody {
    display: grid;
    grid-gap: 6rem 0rem;
    overflow-y: scroll;
    height: 94.5vh;
    overflow-x: hidden;
}

.firstRow {
    padding-top: 5rem;
    padding-left: 10rem;
    height: fit-content;
}

.title {
    font-size: 2rem;
}

.websiteDesc {
    display: flex;
    flex-direction: column;
    gap: 1rem 0rem;
    font-size: 1.4rem;
    margin-top: 1rem;
    line-height: 1.75rem;
    max-width: 85%;
    padding-left: 0.25rem;
}

.featureHighlightTitle {
    color: brown;
    font-weight: bold;
}

.featureHighlight {
    color: brown;
    font-weight: bold;
    cursor: pointer;
}

.featureHighlight:hover {
    text-decoration: underline;
}

.secondRow {
    display: flex;
    background-color: whitesmoke;
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 10rem;
}

.birthdayCarouselParent {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 40%;
    height: fit-content;
    margin-right: 15%;
}

.birthdayCarousel {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    /* Internet Explorer 10+ */
    -ms-overflow-style: none;
    /* Firefox */
    scrollbar-width: none;
    margin-top: 1rem;
    border: 1px solid black;
    background-color: whitesmoke;
    border-radius: 10px;
    scroll-behavior: smooth;
}

.birthdayCarousel::-webkit-scrollbar {
    /* Safari and Chrome */
    display: none;
}

.carouselButtonParent {
    position: absolute;
    bottom: -2rem;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    gap: 0rem 1rem;
    width: fit-content;
}

.carouselButton {
    min-width: 10px;
    min-height: 10px;
    border-radius: 10px;
}

.carouselButton:hover {
    cursor: pointer;
}

.birthdayCardHeader {
    display: flex;
    gap: 0rem 0.5rem;
    justify-content: space-between;
    margin-bottom: 0.4rem;
}

.birthdayCardComposerName {
    font-size: 1.25rem;
    font-weight: bold;
}

.portraitAndWorks {
    display: flex;
    align-items: flex-start;
    width: 100%;
    gap: 0rem 0.75rem;
    padding-top: 0.25rem;
}

.portraitAndWorks>img {
    border-radius: 10px;
}

.recommendedWorksTitle {
    font-size: 1.1rem;
    margin-bottom: 0.25rem;
    font-weight: bold;
    padding-top: 0.1rem;
}

.recommendedWorksParent {
    display: flex;
    flex-direction: column;
    gap: 1rem 0rem;
    width: 100%;
}

.recommendedWorks {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0rem 1rem;
    font-size: 1.1rem;
    padding-left: 1px;
    text-overflow: clip;
}

.recommendedWorks>span {
    padding-top: 0.1rem;
}

.playButton {
    cursor: pointer;
}

.qotdParent {
    width: 35%;
}

.qotdContent {
    display: flex;
    flex-direction: column;
    gap: 1rem 0rem;
    margin-top: 1rem;
}

.qotdQuote {
    line-height: 1.5rem;
}

.qotdItem {
    margin-bottom: 0.5rem;
}

.qotdAuthor {
    margin-top: 0.25rem;
    text-align: end;
    font-weight: bold;
    color: brown;
}

.separator {
    border: 1px solid gray;
    border-radius: 10px;
}

@media (max-width: 1400px) {

    .homeBody {
        max-height: 92vh;
    }

    .firstRow {
        padding-top: 4rem;
        padding-left: 7rem;
    }

    .title {
        font-size: 1.5rem;
    }

    .websiteDesc {
        gap: 0.5rem 0rem;
        font-size: 1.2rem;
        max-width: 75%;
    }

    .portraitAndWorks>img {
        width: 175px;
    }

    .recommendedWorksParent {
        gap: 0.5rem 0rem;
    }

    .recommendedWorks {
        font-size: 0.9rem;
    }

    .secondRow {
        padding-left: 7rem;
    }

    .qotdParent {
        width: 35%;
    }

    .qotdQuote {
        font-size: 0.9rem;
    }

    .qotdItem {
        margin-bottom: 0.25rem;
    }

    .qotdAuthor {
        font-size: 0.9rem;
    }
}

@media (max-width: 1200px) {
    .homeBody {
        grid-gap: 2rem 0rem;
    }

    .firstRow {
        padding-top: 4rem;
        padding-left: 8rem;
    }

    .secondRow {
        display: flex;
        flex-direction: column;
        gap: 5rem 0rem;
        padding-left: 8rem;
    }

    .birthdayCarouselParent {
        width: 70%;
    }

    .birthdayCardComposerName {
        font-size: 1.5rem;
        font-weight: bold;
    }

    .recommendedWorksParent {
        gap: 1rem 0rem;
    }

    .recommendedWorks>span {
        font-size: 1.2rem;
    }

    .portraitAndWorks>img {
        width: 200px;
    }

    .qotdParent {
        width: 70%;
    }

    .qotdQuote {
        font-size: 1.1rem;
    }
}

@media (max-width:1000px) {

    .firstRow {
        padding-top: 3rem;
        padding-left: 7rem;
        width: 85%;
    }

    .secondRow {
        padding-left: 7rem;
    }

    .birthdayCarouselParent {
        width: 60%;
    }

    .birthdayCarousel {
        height: 90%;
    }

    .portraitAndWorks>img {
        width: 190px;
    }

    .recommendedWorks>span {
        font-size: 1.1rem;
    }

    .qotdParent {
        width: 60%;
    }
}

@media (max-width: 860px) {

    .title {
        font-size: 1.25rem;
    }

    .websiteDesc {
        font-size: 1.1rem;
        margin-top: 1rem;
        max-width: 65%;
    }

    .birthdayCarouselParent {
        width: 55%;
    }

    .birthdayCarousel {
        height: 89%;
    }

    .recommendedWorks>span {
        font-size: 1.15rem;
    }

    .qotdParent {
        width: 55%;
    }
}

@media (max-width: 760px) {

    .firstRow {
        padding-left: 6rem;
    }

    .title {
        font-size: 1.1rem;
    }

    .websiteDesc {
        font-size: 1rem;
        max-width: 50%;
    }

    .secondRow {
        padding-left: 6rem;
    }

    .secondRowTitle {
        font-size: 1.6rem;
    }

    .birthdayCarouselParent {
        width: 45%;
    }

    .birthdayCarousel {
        height: 80%;
    }

    .portraitAndWorks>img {
        width: 190px;
    }

    .recommendedWorks>span {
        font-size: 0.95rem;
    }

    .qotdParent {
        width: 45%;
    }

    .qotdQuote {
        font-size: 1rem;
    }
}