@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.applyFadeIn {
  animation: fadeIn 500ms forwards 1;
}

.worksMainBody {
  max-height: 94.5vh;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4rem;
}

.worksBody {
  display: flex;
  flex-direction: column;
  width: fit-content;
  opacity: 0;
  padding-bottom: 4rem;
}

.workTitle {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 3rem;
  display: flex;
  justify-content: flex-start;
}

.filterWorksHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1.5rem;
}

.allGenresParent {
  display: flex;
  gap: 0.3rem;
}

.genreButton {
  border: 0.1rem solid black;
  border-radius: 5px;
  background-color: white;
  padding: 0.20rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.workHeader {
  display: grid;
  grid-template-columns: 35rem 10rem 7rem 1.5rem;
  column-gap: 2rem;
  font-size: 1.5rem;
  font-weight: bold;
}

.workHeaderField {
  display: flex;
  gap: 0rem 0.5rem;
  cursor: pointer;
}

.workCard {
  display: grid;
  grid-template-columns: 35rem 10rem 7rem 1.5rem;
  column-gap: 2rem;
  align-items: center;
  font-size: 1.2rem;
  border-bottom: 1px solid gray;
  padding: 1rem 1rem 1rem 0rem;
  margin-bottom: 1rem;
  white-space: pre-wrap;
  word-break: break-word;
}

.playButton {
  display: inline-block;
  cursor: pointer;
}

.playButtonIcon {
  font-size: 2rem;
}

@media (max-width: 1280px) {
  .worksMainBody {
    max-height: 92vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}


@media (max-width:1125px) {
  .worksMainBody {
    padding-left: 2rem;
  }

  .playButtonIcon {
    font-size: 1.9rem;
  }

  .workHeader {
    grid-template-columns: 28rem 8rem 7rem 1rem;
  }

  .workCard {
    grid-template-columns: 28rem 8rem 7rem 1rem;
  }
}

@media (max-width: 950px) {
  .workTitle {
    font-size: 2rem;
    margin-bottom: 2.5rem;
    width: 59%;
    text-align: left;
  }

  .workHeader {
    grid-template-columns: 22rem 7rem 6rem 1rem;
  }

  .workCard {
    grid-template-columns: 22rem 7rem 6rem 1rem;
    font-size: 1.1rem;
    padding: 0.75rem 1rem 0.75rem 0rem;
  }

  .filterWorksHeader {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    padding-bottom: 1.5rem;
    gap: 0.75rem 0rem;
  }
}

@media (max-width: 800px) {
  .playButtonIcon {
    font-size: 1.75rem;
  }

  .workHeader {
    font-size: 1.3rem;
    grid-template-columns: 15rem 7rem 7rem 0rem;
  }

  .workCard {
    grid-template-columns: 15rem 7rem 7rem 0rem;
    font-size: 1rem;
    padding: 0.75rem 1rem 0.75rem 0rem;
  }

  .filterWorksHeader {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    padding-bottom: 1.5rem;
    gap: 0.75rem 0rem;
  }

  .worksBody {
    max-width: 85%;
  }
}

@media (max-width: 700px) {

  .playButtonIcon {
    font-size: 1.75rem;
  }

  .worksBody {
    max-width: 80%;
  }

  .filterWorksHeader {
    width: 82.5%;
  }

  .allGenresParent {
    flex-wrap: wrap;
  }

  .workHeader {
    font-size: 1.2rem;
    grid-template-columns: 10rem 6rem 6rem 1rem;
  }

  .workCard {
    grid-template-columns: 10rem 6rem 6rem 1rem;
    font-size: 1rem;
  }
}