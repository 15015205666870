.triviaIntroMainBody {
    display: flex;
    flex-direction: column;
    gap: 1rem 0rem;
    width: 100%;
    height: 94.5vh;
    padding-top: 3rem;
    padding-left: 6rem;
    align-items: flex-start;
    border: 1px solid black;
}

.header {
    display: flex;
    flex-direction: column;
    gap: 0.5rem 0rem;
}

.triviaDescription {
    font-size: 1.1rem;
    line-height: 1.25rem;
    max-width: 70vw;
    padding-left: 0.1rem;
}

.startButton {
    padding: 0.5rem 1rem 0.5rem 1rem;
    background-color: brown;
    color: white;
    border-radius: 10px;
    border: none;
    font-size: 1.1rem;
}

.startButton:hover {
    cursor: pointer;
    background-color: rgb(141, 36, 36);
}

.triviaMainBody {
    display: flex;
    flex-direction: column;
    gap: 1rem 0rem;
    align-items: center;
    width: 100%;
    height: 94.5vh;
    overflow-y: scroll;
    padding-top: 3rem;
}

.triviaQuizBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 75vw;
    gap: 1.25rem 0rem;
    z-index: 10;
}

.triviaQuizHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem 0rem;
}

.triviaQuizQuestionCounter {
    font-size: 1.2rem;
}

.triviaQuizBar {
    border: 1px solid black;
    border-radius: 10px;
    width: 32vw;
    min-height: 0.5rem;
    transition: all ease-in-out 1s;
}

.triviaQuizQAParent {
    display: flex;
    flex-direction: column;
    gap: 1rem 0rem;
    width: 60%;
    align-items: center;
}

.triviaQuizQuestion {
    font-size: 1.3rem;
    text-align: center;
    font-weight: bold;
    width: 105%;
}

.triviaQuizAnswer {
    display: flex;
    align-items: center;
    gap: 0rem 0.5rem;
    border-radius: 10px;
    padding: 0.5rem;
    text-align: center;
    cursor: pointer;
    width: 105%;
}

.triviaQuizAnswer>span {
    width: 100%;
}

.buttonParent {
    display: flex;
    justify-content: flex-start;
    gap: 0rem 0.5rem;
    width: 105%;
    margin-top: 0.5rem;
}

.button {
    align-self: flex-end;
    border: 1px solid #a52a2a;
    border-radius: 10px;
    padding: 0.5rem 1rem 0.5rem 1rem;
    color: white;
    background-color: brown;
    font-size: 0.9rem;
    cursor: pointer;
}

.button:hover {
    background-color: rgb(141, 36, 36);
}

.button:disabled {
    background-color: rgba(165, 42, 42, 0.7);
    border: 1px solid rgba(165, 42, 42, 0.1);
    cursor: default;
}

/* Final Result */
.finalResultParent {
    z-index: 10;
    width: 75vw;
    margin-bottom: 5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem 0rem;
    align-items: center;
}

.finalResultParent>* {
    width: 100%;
}

.scoreAndRestart {
    font-size: 2rem;
    width: 63%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.score {
    font-weight: bold;
    padding-right: 1.25rem;
}

.restartQuiz {
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    gap: 0rem 0.25rem;
}

.restartQuizText {
    padding-bottom: 0.1rem;
    font-weight: bold;
}

.timeElapsedFinal {
    font-size: 1.25rem;
    padding-bottom: 0.1rem;
}

.separator {
    width: 63%;
    border: 1px solid black;
}

.restartQuiz:hover {
    color: brown;
    text-decoration: underline;
    cursor: pointer;
}

.allQuiz {
    display: flex;
    flex-direction: column;
    gap: 3rem 0rem;
    align-items: center;
    margin-top: 1rem;
}

.triviaQuizQuestionFinal {
    text-align: start;
}

.triviaQuizAnswerFinal {
    width: 105%;
}

@media (max-width: 1000px) {
    .triviaQuizQAParent {
        width: 80%;
    }

    .triviaQuizHeader {
        width: 80%;
    }

    .triviaQuizBar {
        width: 50vw;
    }
}