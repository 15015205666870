.profileParent {
    display: flex;
    justify-content: center;
    height: 94.5vh;
}

.contentParent {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 75%;
}

.content {
    display: flex;
    height: 100%;
    margin-top: 2rem;
}

.sidebar {
    display: flex;
    flex-direction: column;
    border-right: 1px solid black;
    height: 100%;
    padding-top: 0.25rem;
    padding-right: 1rem;
    gap: 1rem;
    width: 15%;
}

.sidebar>span {
    border-bottom: 1px solid darkgray;
    padding-bottom: 0.25rem;
    cursor: pointer;
    box-sizing: border-box;
}

.contentItem {
    margin-left: 1rem;
    padding-top: 0.1rem;
    display: flex;
    flex-direction: column;
    gap: 0.4rem 0rem;
    min-width: 45%;
    max-width: 45%;
}

.button {
    color: white;
    background-color: brown;
    border: none;
    font-size: 1rem;
    text-align: center;
    padding: 0.5rem;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 0.5rem;
    width: 65%;
}

.button:hover {
    background-color: rgb(141, 36, 36);
}

.button:disabled {
    cursor: not-allowed;
    background-color: rgba(165, 42, 42, 0.7)
}

.afterSubmit {
    display: flex;
    justify-content: center;
    margin-top: 0.25rem;
    gap: 0rem 0.5rem;
}

@media (max-width: 1280px) {

    .sidebar {
        width: 25%;
    }

    .contentParent {
        margin-top: 3rem;
    }

    .contentItem {
        min-width: 75%;
    }
}