/* Filter Item */

.workFilter {
    position: relative;
    display: flex;
}

.workFilterInput {
    padding: 0.5rem 0.5rem;
    padding-right: 1.5rem;
    border-radius: 5px;
    width: 13rem;
    border: 2px solid black;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.workFilterInput:focus {
    outline: none !important;
    border: 2px solid brown;
}

.clearIcon {
    position: absolute;
    right: 0.6rem;
    top: 0.47rem;
    color: black;
}

.clearIcon:hover {
    color: brown;
    cursor: pointer;
}

/* Filter Item */

/* Back To Top */
.backToTop {
    position: fixed;
    right: 2%;
    bottom: 7%;
    transform: translateX(300%);
    z-index: 20;
    border: 0.15rem solid brown;
    border-radius: 50%;
    max-width: 2rem;
    height: 2rem;
    background-color: white;
    transition: background-color 0.2s;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
}

.backToTop:hover {
    cursor: pointer;
    background-color: brown;
}

/* Back To Top */

/* Error Styling */

.errorParent {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 10;
    height: 90vh;
    overflow: hidden;
    z-index: 0;
}

@keyframes slideDown {
    0% {
        transform: translateY(-1000%);
    }

    100% {
        transform: translateY(0%);
    }
}

@keyframes slideLeft {
    0% {
        transform: translateX(1000%);
    }

    100% {
        transform: translateY(0%);
    }
}

@keyframes slideUp {
    0% {
        transform: translateY(1000%);
    }

    100% {
        transform: translateY(0%);
    }
}

.applySlideDown {
    animation: slideDown 1s forwards 1;
    animation-delay: 0ms;
}

.applySlideLeft {
    animation: slideLeft 1s forwards 1;
    animation-delay: 100ms;
}

.applySlideUp {
    animation: slideUp 1s forwards 1;
    animation-delay: 100ms;
}

.errorAccidentalOne {
    position: absolute;
    left: 25%;
    top: 18%;
    transform: translateY(-1000%);
}

.errorAccidentalTwo {
    position: absolute;
    left: 72%;
    top: 27%;
    transform: translateX(1000%);
}

.errorAccidentalThree {
    position: absolute;
    left: 60%;
    top: 75%;
    transform: translateY(1000%);
}

@keyframes showMessage {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.applyShowMessage {
    animation: showMessage 300ms forwards 1;
    animation-delay: 500ms;
}

.errorMessage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem 0rem;
}

.goBack {
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    gap: 0rem 0.25rem;
}

.goBack:hover {
    cursor: pointer;
    color: brown;
    font-weight: bold;
}

/* Error Styling */

/* Prompt Styling */

.promptScreenCover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
}

.promptParent {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 25rem;
    border: 2px solid black;
    border-radius: 10px;
    background-color: white;
    padding: 1rem;
    -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.promptParent>h2 {
    margin-bottom: 0.25rem;
}

.promptButtonsParent {
    display: flex;
    gap: 0rem 1rem;
    justify-content: flex-end;
    padding-top: 1rem;
}

.promptButton {
    color: black;
    font-weight: bold;
    background-color: white;
    border: none;
    font-size: 1rem;
}

.promptButton:hover {
    color: brown;
    text-decoration: underline;
    cursor: pointer;
}

.promptButton:disabled {
    color: darkgray;
}

.promptButton:disabled:hover {
    text-decoration: none;
    cursor: not-allowed;
}

.confirmCheck {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    gap: 0.5rem;
}

.confirmPhraseInput {
    border: none;
    border-bottom: 1px solid black;
    font-size: 0.9rem;
}

.confirmPhraseInput:focus {
    outline: none;
    border-bottom: 2px solid brown;
}

/* Prompt Styling */