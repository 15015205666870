.mainParent {
    display: flex;
    justify-content: center;
    width: 100%;
    overflow-y: scroll;
    max-height: 94vh;
}

.signInParent {
    display: flex;
    flex-direction: column;
    padding-top: 3rem;
    align-items: center;
    max-height: 94vh;
}

.title {
    align-self: flex-start;
}

.noAccount {
    margin-top: 0.5rem;
    font-size: 0.9rem;
    align-self: flex-start;
}

.signUpHyperlink {
    color: brown;
}

.signUpHyperlink:hover {
    cursor: pointer;
    text-decoration: underline;
}

.signInBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 40vw;
    gap: 1.1rem 0rem;
    margin-top: 0.75rem;
}

.signUpBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem 0rem;
    width: 40vw;
    margin-top: 0.75rem;
}

.postSignUpScreen>div {
    text-align: start;
}

.signInField {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.2rem 0rem;
}

.inputLabel {
    font-weight: bold;
    font-size: 0.9rem;
}

.signInInput {
    padding: 0.3rem;
    font-size: 1.1rem;
    border: 1px solid black;
    border-radius: 3px;
    background-color: whitesmoke;
}

.rememberForgot {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9rem;
}

.remember {
    display: flex;
    align-items: center;
    gap: 0rem 0.25rem;
}

.forgot {
    color: brown;
}

.forgot:hover {
    text-decoration: underline;
    cursor: pointer;
}

.signInButton {
    width: 100%;
    border: 1px solid brown;
    border-radius: 5px;
    border: none;
    background-color: brown;
    font-size: 1rem;
    padding: 0.5rem;
    text-align: center;
    color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.signInButton:hover {
    cursor: pointer;
    background-color: rgb(141, 36, 36);
}

.signInButton:disabled {
    cursor: not-allowed;
    background-color: rgba(165, 42, 42, 0.7)
}

.peekPassword {
    position: absolute;
    right: 0.5rem;
    bottom: 0.31rem;
    color: black;
}

.peekPassword:hover {
    color: brown;
    cursor: pointer;
}

.askUserText {
    color: white;
    font-size: 1rem;
}

.separator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.75rem;
    width: 100%;
}

.or {
    display: flex;
    align-items: center;
    gap: 0rem 0.5rem;
    color: white;
    margin-top: 0.75rem;
    margin-bottom: 0.8rem;
}

.bar {
    height: 0.1rem;
    min-width: 45%;
    background-color: darkgray;
    border-radius: 10px;
    margin-top: 0.1rem;
}

.continueWithGoogle {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0rem 0.4rem;
    background-color: white;
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 0.5rem 1rem 0.5rem 1rem;
    margin-top: 0.75rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.continueWithGoogle:hover {
    background-color: lightgray;
    cursor: pointer;
}

.passwordRequirements {
    width: 100%;
    border-radius: 3px;
    border: 1px solid black;
    color: black;
    padding: 0.5rem;
    background-color: white;
}

.passwordReqTitle {
    font-weight: bold;
    padding-bottom: 0.2rem;
}

.passwordReq {
    display: flex;
    align-items: center;
    gap: 0.3rem;
}

/* Password reset */
.afterSubmit {
    display: flex;
    justify-content: center;
    margin-top: 1rem
}

.buttonAndCircleTimer {
    width: 100%;
    display: flex;
    position: relative;
}

.circleTimer {
    position: absolute;
    right: -2rem;
    top: 50%;
    transform: translateY(-50%);
}

@media (max-width: 1000px) {

    .signInParent {
        padding-bottom: 25rem;
    }

    .title {
        font-size: 1.75rem;
    }

    .inputLabel {
        font-size: 0.8rem;
    }

    .signInInput {
        font-size: 1rem;
    }

    .signInBox {
        width: 60vw;
    }

    .signUpBox {
        width: 60vw;
        padding-bottom: 5rem;
    }

    .signInButton {
        font-size: 0.9rem;
    }

    .continueWithGoogle {
        font-size: 0.9rem;
        padding: 0.4rem 1rem 0.4rem 1rem;
    }

    .passwordReq {
        font-size: 0.9rem;
    }
}