.dockedPlayerParent {
    position: fixed;
    width: calc(100% - 3.25rem);
    height: 3.5rem;
    left: 3.25rem;
    bottom: 0;
    z-index: 100;
    border-top: 2px solid brown;
    color: black;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0rem 1rem;
}

.songMetaData {
    display: flex;
    align-items: center;
    width: 35%;
    justify-content: flex-start;
    gap: 0.6rem;
    padding-top: 0.2rem;
}

.portrait {
    border-radius: 5px;
}

.songTitle {
    font-weight: bold;
    cursor: text;
    overflow: hidden;
    white-space: nowrap;
    min-width: 70%;
    max-width: 70%;
    display: flex;
    -webkit-mask-image: linear-gradient(to left, transparent 0%, black 2%, black 99%, transparent 100%);
    mask-image: linear-gradient(to left, transparent 0%, black 2%, black 99%, transparent 100%);
    font-size: 1.1rem;
}

@keyframes textCarousel {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(-100%);
    }
}

.songTitleAnim {
    width: auto;
    padding-right: 2rem;
    padding-left: 0.1rem;
    animation: textCarousel 12s linear infinite;
    animation-delay: 3000ms;
}

.songTitleNoAnim {
    padding-right: 0rem;
    padding-left: 0rem;
    animation: none;
}

.by {
    font-size: 0.8rem;
    text-align: center;
}

.timeAndBar {
    display: flex;
    align-items: center;
    gap: 0rem 0.5rem;
}

.time {
    font-size: 0.9rem;
}

.progressBar {
    border: 1px solid black;
    border-radius: 10px;
    width: 100vw;
    height: 0.4rem;
    cursor: pointer;
    background: linear-gradient(90deg, brown 50%, white 0%);
}

.controls {
    display: flex;
    align-items: center;
    padding-right: 1rem;
}

.controls>* {
    padding-left: 1rem;
    padding-right: 1rem;
}

.volumeParent {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
}

.volIcon {
    padding: 1rem 0rem 1rem 0rem;
    cursor: pointer;
    z-index: 0;
}

.volumeHoverArea {
    position: absolute;
    min-width: 4rem;
    min-height: 12rem;
    transform: translateY(-95%);
    z-index: 1;
}

.volumeBar {
    position: absolute;
    transform: translateY(-120%);
    z-index: 2;
    background-color: white;
    border: 2px solid brown;
    border-radius: 10px;
    padding: 1.5rem 0.5rem 0.75rem 0.5rem;
}

.upNextParent {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.UpNext {
    position: absolute;
    transform: translate(-15%, -115%);
    border: 2px solid brown;
    border-radius: 10px;
    background-color: white;
    min-height: 15rem;
    max-height: 15rem;
    min-width: 30rem;
    max-width: 22rem;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px 0px;
    display: flex;
    flex-direction: column;
    z-index: 2;
}

.upNextIcon {
    padding: 1rem 0rem 1rem 0rem;
    z-index: 0;
}

.upNextHoverArea {
    position: absolute;
    width: 32.5rem;
    height: 20rem;
    transform: translate(-27%, -97%);
    padding-top: 1rem;
    z-index: 1;
}

.upNextTitleWrapper {
    position: absolute;
    width: calc(100% - 1rem);
    z-index: 10;
    background-color: white;
    padding-left: 1rem;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    border-radius: 10px;
}

.upNextContent {
    overflow-y: scroll;
    padding-top: 1.75rem;
    z-index: 1;
    padding-left: 1rem;
    padding-right: 1rem;
}

.queuedSong {
    position: relative;
    margin-top: 1rem;
    border-bottom: 1px solid gray;
    padding-bottom: 0.25rem;
}

@media (max-width: 1550px) {
    .songTitle {
        /* min-width: 60%; */
        /* max-width: 60%; */
        font-size: 1.1rem;
    }

    .songMetaData {
        width: 30%;
        padding-right: 1rem;
    }
}

@media (max-width: 1000px) {

    .songMetaData {
        width: 35%;
    }

    .controls {
        display: flex;
        align-items: center;
        padding: 0rem;
    }

    .controls>* {
        padding-left: 0.6rem;
        padding-right: 0.6rem;
    }
}