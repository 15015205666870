@keyframes slideDown {
    0% {
        transform: translateY(-300%);
    }

    100% {
        transform: translateY(0%);
    }
}

@keyframes slideUp {
    0% {
        transform: translateY(0%);
    }

    100% {
        transform: translateY(-300%);
    }
}

/* To apply animations using loading.module.css and ternary operations */
.applySlideDown {
    animation: slideDown 300ms forwards 1;
}

.applySlideUp {
    animation: slideUp 300ms forwards 1;
}

.loadingParent {
    position: absolute;
    top: 6rem;
    left: 0;
    right: 0;
    z-index: 1;
    transform: translateY(-300%);
    overflow-y: hidden;
    height: 50%;
}

.loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem 0rem;
    height: 100%;
}

.loadingTitle {
    font-size: 2rem;
    font-weight: bold;
}

.loadingGraphic {
    display: flex;
    height: 100%;
}