.musicPlayerBody {
  position: fixed;
  width: 40rem;
  height: 10rem;
  left: 5rem;
  top: 4.75rem;
  z-index: 100;
  padding-left: 5rem;
}

.musicPlayer {
  position: absolute;
  width: 35rem;
  height: 10rem;
}

.musicPlayerBox {
  position: absolute;
  border: 2px solid brown;
  background-color: white;
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
  width: calc(100% - 5rem);
  gap: 0rem 1rem;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px;
  /* cursor: grab; */
  z-index: 20;
}

.closeButton {
  position: absolute;
  right: 0.5rem;
  top: 0.25rem;
}

.composerPortrait {
  display: flex;
  flex-direction: column;
  margin: 0.75rem 0rem 0.75rem 1rem;
  cursor: default;
}

.composerPortrait>img {
  border-radius: 10px;
}

.songInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.9rem;
  width: 20rem;
  gap: 0.3rem 0rem;
}

.songHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 99%;
  height: 2.5rem;
  overflow: hidden;
  gap: 0.25rem 0rem;
}

.songTitle {
  font-weight: bold;
  cursor: text;
  overflow: hidden;
  white-space: nowrap;
  width: 99%;
  display: flex;
  -webkit-mask-image: linear-gradient(to left, transparent 0%, black 2%, black 99%, transparent 100%);
  mask-image: linear-gradient(to left, transparent 0%, black 2%, black 99%, transparent 100%);
}

.songTitleAnim {
  width: auto;
}

@keyframes textCarousel {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.songArtist {
  font-size: 0.8rem;
  cursor: text;
}

.songPlayer {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.songDuration {
  display: flex;
  align-items: center;
  width: 20rem;
  justify-content: space-around;
}

.songTime {
  font-size: 0.8rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.progressBar {
  border: 1px solid black;
  border-radius: 10px;
  width: 14rem;
  margin-top: 0.2rem;
  height: 0.4rem;
  cursor: pointer;
}

.controls {
  align-self: center;
  display: flex;
  justify-content: space-between;
  width: 14rem;
  margin-top: 0.1rem;
}

.controls>* {
  cursor: pointer;
}

.volumeBox {
  position: absolute;
  left: 31.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem 0rem;
  animation: slideRight 200ms;
  z-index: 10;
  background-color: white;
  border: 2px solid brown;
  border-radius: 10px;
  padding: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px;
}

.currentVol {
  margin-bottom: 20rem;
}

.slider {
  margin-right: 10000px;
}