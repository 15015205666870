@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.applyFadeIn {
  animation: fadeIn 500ms forwards 1;
}

.viewComposerMainBody {
  padding-top: 2rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  gap: 0rem 5rem;
  opacity: 0;
  padding-left: 4rem;
  overflow-y: scroll;
  max-height: 94.5vh;
}

.left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 5rem;
  gap: 1rem 0rem;
  max-width: 50%;
}

.composerBody {
  display: flex;
  flex-direction: column;
  gap: 1rem 0rem;
}

.composerHeader {
  display: flex;
  flex-direction: column;
  gap: 0.5rem 0rem;
}

.composerName {
  font-size: 2.5rem;
  font-weight: bold;
  padding-right: 1rem;
}

.composerFacts {
  font-size: 1.2rem;
  margin-left: 0.1rem;
}

.composerImage {
  width: 15.5rem;
  float: left;
  margin-right: 1rem;
  border-radius: 10px;
}

.composerDescription {
  line-height: 1.75rem;
  font-size: 1.1rem;
}

.exploreCard {
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
  min-width: 90%;
}

.exploreButton {
  margin-top: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.exploreGenres {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  gap: 1rem 0rem;
  margin: 1rem 0rem 1rem 0rem;
  overflow: hidden;
}

.genreCard {
  border: 1px solid gray;
  border-radius: 10px;
  height: 9rem;
  width: 9rem;
  text-align: center;
  vertical-align: middle;
  line-height: 9rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  transition: background-color 0.1s, color 0.1s;
  text-decoration: none;
  color: black;
}

.genreCard:hover {
  color: white;
  background-color: brown;
  cursor: pointer;
}

.right {
  display: flex;
  flex-direction: column;
  gap: 1rem 0rem;
  margin-top: 3rem;
  max-width: 35%;
}

.timeLineHeader {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.timeLine {
  display: flex;
  gap: 0rem 2rem;
  width: 37.5vw;
}

.timeLineArt {
  display: grid;
  grid-template-rows: 0.8rem 8rem 0.8rem 8rem 0.8rem 8rem 0.8rem 8rem;
  justify-items: center;
  margin-left: 0.4rem;
  margin-top: 0.4rem;
}

.dot {
  height: 10px;
  width: 10px;
  outline: 5px solid brown;
  border-radius: 50%;
}

.line {
  border-left: 5px solid brown;
  height: 8rem;
}

.timeLineWords {
  display: grid;
  grid-template-rows: 8.8rem 8.8rem 8.8rem 8.8rem;
  font-size: 1.1rem;
}

/* Key Frames  */
@keyframes noteAnimation {
  0% {
    transform: translateY(0%);
  }

  50% {
    transform: translateY(-50%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(0%);
    display: block;
    opacity: 100%;
  }

  100% {
    transform: translateY(-300%);
    display: none;
    opacity: 0%;
  }
}

@keyframes arrowDown {
  0% {
    opacity: 0;
    height: 0;
  }

  100% {
    opacity: 1;
    height: auto;
  }
}

@keyframes arrowUp {
  0% {
    opacity: 1;
    height: auto;
  }

  100% {
    opacity: 0;
    height: 0;
  }
}

@media (max-width: 1280px) {

  .viewComposerMainBody {
    max-height: 92vh;
  }

  .left {
    padding-left: 3rem;
    gap: 0.5rem 0rem;
  }

  .viewComposerMainBody {
    padding-top: 2rem;
    gap: 0rem 4rem;
  }

  .composerImage {
    width: 200px;
  }

  .composerDescription {
    line-height: 1.5rem;
    font-size: 1rem;
  }

  .exploreGenres {
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem 1rem;
    margin: 1rem 0rem 1rem 0rem;
  }

  .genreCard {
    border-radius: 10px;
    height: 8rem;
    width: 8rem;
    line-height: 8rem;
  }

  .right {
    max-width: 40%;
  }

  .timeLine {
    gap: 0rem 1rem;
  }

  .timeLineWords {
    grid-template-rows: 8.8rem 8.8rem 8.8rem 8.8rem;
    font-size: 1rem;
  }
}

@media (max-width: 1000px) {

  .viewComposerMainBody {
    padding-top: 2rem;
    gap: 0rem 4rem;
    flex-direction: column;
  }

  .left {
    padding-left: 3rem;
    gap: 0.5rem 0rem;
    max-width: 90%;
  }

  .composerImage {
    width: 200px;
  }

  .exploreGenres {
    grid-template-columns: repeat(5, 1fr);
  }

  .right {
    margin-top: 1rem;
    min-width: 90%;
    padding-left: 3rem;
    padding-bottom: 4rem;
  }

  .timeLine {
    gap: 0rem 1rem;
    width: 85vw;
  }

  .timeLineWords {
    grid-template-rows: 8.8rem 8.8rem 8.8rem 8.8rem;
    font-size: 1.2rem;
  }
}

@media (max-width: 845px) {
  .exploreGenres {
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem 2rem;
  }

  .timeLine {
    width: 75vw;
  }
}

@media (max-width: 725px) {
  .exploreGenres {
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem 3rem;
  }
}