@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.applyFadeIn {
    animation: fadeIn 500ms forwards 1;
}

.mapPage {
    opacity: 0;
    overflow: hidden;
}

.mapBody {
    height: 94.5vh;
    width: 96vw;
    margin-left: 4rem;
}

.mapHeader {
    display: flex;
    flex-direction: row;
    gap: 0rem 0.5rem;
    position: relative;
}

.infoParent {
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    z-index: 10;
    left: 0.85vw;
    top: 10vh;
    background-color: white;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.infoParent:hover {
    background-color: #e2e2e2;
    cursor: pointer;
}

.infoParent:hover>.infoWords {
    opacity: 1;
    visibility: visible;
}

.infoWords {
    opacity: 0;
    visibility: hidden;
    transition: all 200ms ease-in-out;
    cursor: text;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 30%;
    margin-left: 2rem;
    font-size: 0.8rem;
    width: 28.25rem;
    border-radius: 2px;
    background-color: white;
    padding: 0.55rem 0.2rem 1.9rem 0.7rem;
    z-index: 10;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nameBox {
    font-size: 0.9rem;
    padding-left: 0.2rem;
}

@media (max-width: 1280px) {

    .mapBody {
        height: 92.5vh;
        margin-left: 3.25rem;
    }

    .infoWords {
        padding: 0.45rem 0.2rem 1.9rem 0.7rem;
    }
}

@media (max-width: 700px) {
    .infoParent {
        left: 1.41vw;

    }
}