@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.playlistParent {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.applyFadeIn {
    animation: fadeIn 500ms forwards 1;
}

.playlistBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80vw;
    height: 94.5vh;
}

.title {
    margin-top: 3rem;
    align-self: flex-start;
}

.playlistContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.noPlaylists {
    display: flex;
    flex-direction: column;
    gap: 0.5rem 0rem;
    width: 100%;
}

.noPlaylists>span {
    font-size: 1.1rem;
}

.createPlaylist {
    background-color: brown;
    color: white;
    border-radius: 10px;
    padding: 0.75rem 0rem 0.75rem 0rem;
    cursor: pointer;
    text-align: center;
    width: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0rem 0.25rem;
    margin-top: 0.5rem;
}

.createPlaylist:hover {
    background-color: rgb(141, 36, 36);
}

.playlists {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    width: 100%;
    max-height: 94.5vh;
    overflow-y: auto;
    gap: 1.75rem 0rem;
}

.playlistTitleParent {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 1rem;
}

.playlistTitle {
    font-size: 1.45rem;
}

.playlistTitleAndArrow {
    display: flex;
    align-items: center;
    cursor: pointer;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

.playlistActionsParent {
    display: flex;
    justify-content: space-between;
    gap: 0rem 0.75rem;
    cursor: pointer;
}

.playlistWorksParent {
    display: flex;
    flex-direction: column;
    gap: 1.5rem 0rem;
    font-size: 1.1rem;
}

.playlistWork {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid gray;
    padding-bottom: 0.5rem;
}

/* New Playlist */
.newPlaylistParent {
    display: flex;
    height: 94.5vh;
    justify-content: center;
}

.newPlaylistBody {
    max-height: 94.5vh;
    width: 100%;
    padding-left: 8rem;
    padding-right: 8rem;
    overflow-y: auto;
}

.newPlaylistForm {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 2rem 0rem;
    margin-bottom: 8rem;
}

.newPlaylistNameParent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem 0rem;
    height: fit-content;
    margin-top: 1rem;
}

.newPlaylistNameParent>label {
    color: brown;
    font-weight: bold;
}

.newPlaylistName {
    position: relative;
    font-size: 1.5rem;
    border: none;
    outline: none;
    border-bottom: 1px solid black;
    width: 25rem;
}

.newPlaylistName:focus {
    border: none;
    outline: none;
    border-bottom: 2px solid brown;
}

.buttonsParent {
    display: flex;
    gap: 0rem 1rem;
    align-items: center;
}

.createButton {
    color: white;
    background-color: brown;
    border: 2px solid brown;
    font-size: 1rem;
    text-align: center;
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 5rem;
}

.createButton:hover {
    background-color: rgb(141, 36, 36);
    border-color: rgb(141, 36, 36);
}

.cancelButton {
    color: brown;
    background-color: white;
    border: 2px solid brown;
    font-size: 1rem;
    text-align: center;
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 5rem;
}

.cancelButton:hover {
    background-color: rgb(141, 36, 36);
    border-color: rgb(141, 36, 36);
    color: white;
}

.searchForWorkParent {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem 0rem;
}

.searchForWorkParent>label {
    color: brown;
    font-weight: bold;
}

.searchForWorkItem {
    position: relative;
    display: flex;
    align-items: center;
    gap: 0rem 0.5rem;
    height: fit-content;
    margin-top: 0.5rem;
}

.searchForWorkItem>img {
    cursor: pointer;
}

.searchParent {
    width: 100%;
}

.searchForWork {
    width: 100%;
    border: none;
    border-bottom: 1px solid black;
    margin-top: 0.25rem;
    margin-bottom: 1rem;
    font-size: 1.3rem;
}

.searchForWork:focus {
    width: 100%;
    border: none;
    outline: none;
    border-bottom: 2px solid brown;
    margin-top: 0.25rem;
    margin-bottom: 1rem;
}

.searchIcon {
    position: absolute;
    right: 0.1rem;
}

.deleteButton {
    position: absolute;
    right: -2rem;
    cursor: pointer;
}

.dropdownListParent {
    position: absolute;
    top: 1.75rem;
    border: 1px solid brown;
    background-color: white;
    width: 100%;
    border-radius: 10px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    max-height: 15rem;
    overflow-y: scroll;
}

.dropdownListItem {
    background-color: white;
    color: black;
    cursor: pointer;
    padding: 0.75rem;
    border-radius: 10px;
    margin-top: 0.25rem;
}

.workTitleAndIcon {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.addedWork {
    position: relative;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid black;
    margin-top: 0.5rem;
    margin-bottom: 0.75rem;
    font-size: 1.3rem;
}

@media (max-width: 1280px) {
    .playlistBody {
        max-height: 92vh;
    }

    .newPlaylistBody {
        max-height: 92vh;
    }
}

@media (max-width: 1000px) {
    .newPlaylistForm {
        padding-bottom: 12rem;
    }
}

@media (max-width: 850px) {
    .playlistBody {
        width: 75vw;
    }

    .playlistWork {
        font-size: 1rem;
    }

    .addedWork {
        font-size: 1.15rem;
    }

    .newPlaylistBody {
        padding-left: 6rem;
        padding-right: 6rem;
    }
}