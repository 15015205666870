.navBar {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #a52a2a;
    z-index: 10;
    min-height: 3.5rem;
    max-height: 3.5rem;
}

.navTitle {
    color: white;
    font-size: 2.5rem;
    display: flex;
    align-items: center;
}

.navTitleLogo {
    width: 50px;
}

.signIn {
    padding-right: 2rem;
    color: white;
    font-size: 1.25rem;
}

.signIn:hover {
    cursor: pointer;
}

.sidebarParent {
    position: absolute;
    height: 94.5vh;
    border-right: 1px solid black;
    overflow-x: hidden;
    box-shadow: 10px 0 10px -8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    background-color: white;
    overflow-y: hidden;
}

.sidebar {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem 0rem;
    padding-top: 0.5rem;
    height: inherit;
}

.sidebarItem {
    display: flex;
    align-items: center;
    margin-left: 0.3rem;
    gap: 0rem 1.25rem;
    width: 95%;
    border-radius: 10px;
    border: 1px solid white;
    padding: 0.3rem 0rem 0.25rem 0.25rem;
    white-space: nowrap;
    transition: all ease-in-out 100ms;
}

.sidebarItem:hover {
    background-color: brown;
    cursor: pointer;
}

.darkModeItem {
    display: flex;
    align-items: center;
    margin-left: 0.3rem;
    gap: 0rem 1rem;
    width: 95%;
    border-radius: 10px;
    border: 1px solid white;
    padding: 0.3rem 0rem 0.25rem 0.25rem;
    font-size: 0.5rem;
    white-space: nowrap;
    margin-bottom: 0.5rem;
}

.placeDarkmodeBottom {
    margin-top: auto;
}

.darkModeSwitch {
    padding-top: 0.3rem;
}

.sidebarText {
    padding-top: 0.2rem;
}

.usernameAndSilhouette {
    display: flex;
    align-items: center;
    gap: 0rem 1rem;
}

.usernameAndSilhouette>div:first-of-type {
    color: white;
    font-size: 1.25rem;
}

.profileIcon {
    margin-right: 1vw;
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
    background-color: white;
    padding: 0.5rem 0.6rem;
    border-radius: 50%;
}

.profileIcon:hover {
    background-color: lightgray;
}

.hiddenProfileBox {
    position: absolute;
    right: 1rem;
    transform: translateY(-10%);
    padding-top: 3rem;
}

.profileBox {
    position: relative;
    border: 1px solid black;
    background-color: white;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem 0rem;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 5px 0px;
}

.profileButtonParent {
    display: flex;
    gap: 0rem 0.75rem;
    font-size: 1.25rem;
    align-items: center;
    justify-content: flex-start;
    border-radius: 10px;
    padding: 0.25rem 1rem 0.25rem 0.5rem;
}

.profileButtonParent:hover {
    cursor: pointer;
    background-color: brown;
    color: white;
}

.profileButtonIcon {
    padding-top: 0.1rem;
}

@media (max-width:1280px) {

    .navBar {
        min-height: 3rem;
        max-height: 3rem;
    }

    .navTitle {
        font-size: 2rem;
    }

    .navTitleLogo {
        width: 40px;
    }

    .hiddenProfileBox {
        transform: translate(-5%, -10%);
    }

    .profileBox {
        gap: 0.5rem 0rem;
        padding: 0.75rem;
    }

    .profileButtonParent {
        font-size: 1.1rem;
    }
}